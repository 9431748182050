<template>
  <div class="profil">
    <div class="naglowek">
        <h1>Kalendarz</h1>
    </div>
    <div class="treeselect">
      <Treeselect
        :searchable=false
        :clearable=false
        placeholder="Wybierz komisję"
        
        :multiple="true"
        :options="options"
        v-model="value"
        :default-expand-level="1"
        >
        <div slot="value-label" slot-scope="{ node }">{{ node.raw.customLabel }}</div>
        </Treeselect>
    </div>

    <div class="picker">
    <v-date-picker 
      :attributes='attrs' 
      v-model="selected_day"
      :model-config="modelConfig"
      :select-attribute="selectAttribute"

      @update:to-page="changeCalendarMonth"
      
      is-dark
      is-expanded
    />
    </div>

    
    <calendarDayEvents
      :selected_day="this.selected_day"
      :date="selected_day"
      :events="this.events_desc_list"
      ref="calendarDayEvents"
    />


    <div class="communicat_no_events" v-if="events_desc_list.length < 1 && (selected_day==null || selected_day==='')">
      Brak nadchodzących wydarzeń
    </div>

  </div>
</template>
<script>

//      is-required

//      is-expanded

  // import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@/vue-treeselect.css'

import calendarDayEvents from "@/components/calendarDayEvents.vue";

export default {
  name: "Kalendarz",
  components: {
    calendarDayEvents,
    Treeselect,
  },
  data() {
    return {

      value: [0],
      options: this.$store.state.options,


      my_commision: 2,
      commision_colors: this.$store.state.commision_colors,
      select_commisions:  this.$store.state.select_commisions,
   

      events_desc_list: [],

      backend_data_filtered: {},

      backend_data: [
          {
            year: 2022,
            month: 2,
            day: 10,
            events: [
              {
                commisions: [
                  1,
                ],
                note: "Impreza walentynkowa",
                time_hour: "19",
                time_minute: "00",
                is_done: false,
              },
            ]
          },
          {
            year: 2022,
            month: 2,
            day: 14,
            events: [
              {
                commisions: [
                  2,
                ],
                note: "Spotkanie w rektoracie",
                time_hour: "15",
                time_minute: "30",
                is_done: true,
              },
              {
                commisions: [
                  5,3,
                ],
                note: "Trzeba zrobić to i tamto",
                time_hour: "18",
                time_minute: "20",
                is_done: false,
              },
            ]
          }
      ],
      


      selected_day: null,
      
      selectAttribute: {
        highlight:{
          fillMode: 'solid',
        }
      },

      modelConfig: {
        type: 'string',
        mask: 'DD.MM.YYYY',
      },


      attrs_initial: [
        {
          key: 'today',
          highlight:{
            fillMode: 'outline',
          },
          dates: new Date(),
        },
      ],

      attrs: [],

    };
  },
  
  created() {

    //todo pobrac z backendu tylko dane odpowiedzialne za ten miesiac


    console.log("loggedUserId: "+this.$store.state.loggedUserId)


    this.getBackendDataFiltered();

    this.getNearestEvents()

    this.displayCalendar()




//////////////////
/*

if (!("Notification" in window)) {
    alert("This browser does not support desktop notification");
  }

  // Let's check whether notification permissions have already been granted
  else if (Notification.permission === "granted") {
    // If it's okay let's create a notification
     new Notification("Hi there!");
  }

  // Otherwise, we need to ask the user for permission
  else if (Notification.permission !== "denied") {
    Notification.requestPermission().then(function (permission) {
      // If the user accepts, let's create a notification
      if (permission === "granted") {
         new Notification("Hi there!");
      }
    });
  }

*/


////////


  },
  methods: {
    getBackendDataFiltered(){
      
      if(!this.value.includes(0)){

/*
          this.backend_data_filtered = this.backend_data.filter(date =>{
            return date.events.some(event => {
              return event.commisions.some( commision => this.value.includes(commision))
            })
          })
*/
          this.backend_data_filtered = [] 
          for(let date of this.backend_data){
            //console.log("a")
            //console.log(date.events)
            //console.log("value")
            //console.log(this.value)

            let new_date = Object.assign({}, date);

            new_date.events = new_date.events.filter(event => {
            
              
              return event.commisions.some( commision => {
                return this.value.includes(commision)
              })
              
            })
            //console.log("b")
            //console.log(date.events)
            if(date.events.length > 0)
              this.backend_data_filtered.push(new_date)

          }
          
        }
        else
        
          this.backend_data_filtered = this.backend_data
    },
    changeCalendarMonth(newMonth) {
      console.log('changeDate called: '+newMonth.month + "."+newMonth.year);
      
      //todo pobrac z backendu nowy miesiac (+/- 1)
    },

    getNearestEvents(){
          this.events_desc_list = []
          
          let today = new Date();


          for(let date of this.backend_data_filtered){

            if(date.year < today.getFullYear())
              continue;
            if(date.year == today.getFullYear() && date.month < today.getMonth()+1 )
              continue;
            if(date.year == today.getFullYear() && date.month == today.getMonth()+1 && date.day < today.getDate())
              continue;

            for(let event of date.events){

              if(date.year == today.getFullYear() && date.month == today.getMonth()+1 
                && date.day == today.getDate() && event.time_hour< today.getHours())
                continue;
              if(date.year == today.getFullYear() && date.month == today.getMonth()+1 
                && date.day == today.getDate() && event.time_hour == today.getHours() 
                && event.time_minute < today.getMinutes())
                continue;

              event.day = date.day
              event.month = date.month
              event.year = date.year

              this.events_desc_list.push(event)

              if(this.attrs.length > 5)
                return
            }
          }
    },

    displayCalendar(){

        this.attrs = []
        this.attrs.push(...this.attrs_initial)

        let backend_dates = this.backend_data_filtered
        console.log(this.attrs_initial)
        for ( let backend_date of backend_dates){
            for ( let event of backend_date.events){

              let bar_on_calendar = {
                dates: [
                  new Date(backend_date.year, backend_date.month-1, backend_date.day),
                ],
                bar:{
                  style:{}
                }
              }

              if(event.commisions.includes(this.my_commision))
                bar_on_calendar.bar.style.backgroundColor = this.commision_colors.get(this.my_commision)
              else
                bar_on_calendar.bar.style.backgroundColor = this.commision_colors.get(event.commisions[0])

              this.attrs.push(bar_on_calendar)
                
            }
        }
    },

    refreshEventList(new_date = this.selected_day){
        if(new_date === '' || new_date == null){
            this.getNearestEvents()

        } else{
        

            let new_year = new_date.split(".")[2]
            let new_month = new_date.split(".")[1]
            let new_day = new_date.split(".")[0]

            let day_events_tmp = this.backend_data_filtered.find(date => {
                if(date.year != new_year)
                  return false
                if(date.month != new_month)
                  return false
                if(date.day != new_day)
                  return false
                return true
            })

            

            this.$refs.calendarDayEvents.closeEventCreation();

            if(day_events_tmp){
                for(let e of day_events_tmp.events){
                e.year = new_year;
                e.month = new_month;
                e.day = new_day;
              }

              this.events_desc_list = day_events_tmp.events
            }
            else
              this.events_desc_list = [];


            


        }

        this.$refs.calendarDayEvents.closeEventEdit();

    },
  },

  watch:{
    value(){
      //console.log(this.value)
      this.getBackendDataFiltered()
      this.refreshEventList()
      this.displayCalendar()


    },
    
    selected_day(new_date,){
      //console.log(new_date)
      this.refreshEventList(new_date)


      //console.log(this.events_desc_list)

    }
  }
};
</script>
<style lang="scss" scoped>

.picker{
  padding: 20px;
}

.treeselect{
  margin-left:20px;
  margin-right:20px;
}

.communicat_no_events{
  margin: 20px;
}


</style>