<template>
  <div class="DayDesc">
    <h1 v-if="this.selected_day">{{date}}</h1>
    <h2 v-else>Najbliższe wydarzenia:</h2>

    <div v-for="event in this.events" :key="event.id">
        <calendarOneEvent :event="event" :selected_day="selected_day"
        ref="events" />
    </div>

    <calendarCreateEvent
      v-if="this.selected_day"
      :selected_day="this.selected_day"
      ref="calendarCreateEvent"

      />

  </div>
</template>
<script>
import calendarOneEvent from "@/components/calendarOneEvent.vue";
import calendarCreateEvent from "@/components/calendarCreateEvent.vue";


export default {
  name: "calendarDayEvents",
  components: {
    calendarOneEvent,
    calendarCreateEvent,
  },
  props:{
      selected_day: String,
      events: Array,
      date: String,
  },
  data() {
    return {

    };
  },
  
  created() {
   
  },
  methods: {
      closeEventCreation(){
        if(this.$refs.calendarCreateEvent != null)
          this.$refs.calendarCreateEvent.on_start();
      },


      closeEventEdit(){
        if(this.events != null)
          for(let i=0; i<this.events.length; i++){
            this.$refs.events[i].edit_on_start();
          }
      },
  },
  watch:{
    }
};
</script>
<style lang="scss" scoped>



</style>