<template>
  <div class="creation">


    <div class="EventDesc" >

        <h3 v-if="is_organizer">Edycja wydarzenia</h3>
        <h3 v-else>Szczegóły wydarzenia</h3>

            <input :readonly="!is_organizer" v-model="title" placeholder="Wprowadź nazwę wydarzenia">

            <div class="flex_container select_time">
              
                <div v-if="is_organizer">
                  <v-date-picker v-model="date" mode="dateTime" is24hr is-dark :minute-increment="5">
                    <template v-slot="{ inputValue, inputEvents }">
                      <input
                        class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                    </template>
                  </v-date-picker>
                </div>

                <div v-else>
                  <input  :value="event.day + '.'+ event.month + '.' + event.year + ' ' + event.time_hour+':'+event.time_minute" placeholder="Wprowadź nazwę wydarzenia">
                </div>

            </div>

            <Treeselect
            :disabled="!is_organizer"
            
            class="treeselect"
            :searchable=false
            :clearable=false
            placeholder="Wybierz kategorię"
            
            :multiple="true"
            :options="options"
            v-model="value"
            :default-expand-level="1"
            >
                <div slot="value-label" slot-scope="{ node }">{{ node.raw.customLabel }}</div>
            </Treeselect>


            <div v-if="is_organizer" class="checkbox" >
              <i v-if="this.checked" @click="checkbox_clicked" class="fa-solid fa-circle-check"></i>
              <i v-else @click="checkbox_clicked" class="fa-regular fa-circle"></i>
              <span @click="checkbox_clicked">Wyślij maila informującego</span>
            </div>


        <div v-if="is_organizer" class="buttons_container">

            <div v-if="loading_buttons">
                  <loadingItem small />

            </div>

            <div v-else>

                <div v-if="!confirm_to_be_clicked" class="flex_container przyciski">

                      <button @click="creation_cancel"> Anuluj </button>

                      <button @click="creation_delete"> Usuń </button>

                      <button @click="creation_create_button"> Zatwierdź </button>

                  </div>


                <div v-else class="flex_container przyciski">

                      <button @click="cancel_confirm"> Anuluj </button>


                      <button v-if="this.create_button_clicked" @click="create_confirm"> Potwierdź edycję </button>

                      <button v-else @click="delete_confirm" > Potwierdź usunięcie </button>

              </div>

          </div>

        </div>

    </div>


  </div>
</template>
<script>
import Treeselect from '@riophae/vue-treeselect'

import loadingItem from "@/components/loadingItem.vue";

export default {
  name: "calendarDayEvents",
  components: {
    Treeselect,
    loadingItem,
  },
  props:{
      is_organizer: Boolean,
      selected_day: String,
      event: Object,
  },
  data() {
    return {
      //show: false,
      date: {},
      title: "",

      value: [],
      options: this.$store.state.options_create,

      checked: false,

      confirm_to_be_clicked: false,
      create_button_clicked: false,
      loading_buttons: false,
    };
  },
  
  created() {
    this.on_start();
  },
  methods: {
    creation_clicked(){

          this.title = this.event.note;

          this.value = this.event.commisions;
          this.date = new Date(this.event.year, this.event.month-1, this.event.day,
                              this.event.time_hour, this.event.time_minute)

          //console.log(this.event.year, this.event.month, this.event.day,
          //                    this.event.time_hour, this.event.time_minute)
                        
          this.confirm_to_be_clicked= false;
          this.create_button_clicked= false;

    },

    cancel_confirm(){
        this.confirm_to_be_clicked= false;
        this.create_button_clicked= false;
    },

    create_confirm(){
        // todo update event to backend

    },

    delete_confirm(){
        //todo delete event from backend

    },

    creation_create_button(){
        this.confirm_to_be_clicked= true;
        this.create_button_clicked= true;

        this.loading_buttons = true;
        setTimeout(() => {
          this.loading_buttons = false;
        }, 400)
    },

    creation_cancel(){
        this.$parent.edit_on_start();
    },

    creation_delete(){
        this.confirm_to_be_clicked= true;
        this.create_button_clicked= false;

        this.loading_buttons = true;
        setTimeout(() => {
          this.loading_buttons = false;
        }, 400)
    },
    
    checkbox_clicked(){
      this.checked = !this.checked;
    },
    
    on_start(){
      this.value = [];
      this.title = "";
      this.confirm_to_be_clicked= false;
      this.create_button_clicked= false;
    },
  },
  watch:{
    }
};
</script>
<style lang="scss" scoped>

.creation{
  //height: 0px;
  margin-bottom: 40px;
}

.EventDesc{
  background-color: var(--bottom_gradient);
  border-radius: 5px;
  border: 1px solid var(--top_gradient);
  margin: 10px;
  margin-top: -6px;
  padding: 10px 10px 0 10px;

}

.create_button{
  margin: 30px;
}
.create_button>p{
  margin: 6px;
}


.flex_container{
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.buttons_container{
  height: 50px;
}

.confirm{
  display:flex;
  flex-direction: column;
}

.confirm > button{
  margin: 10px;
  padding: 10px;
}

.select_time>div{
  flex-grow: 1;
}

.select_time_text{
  text-align: right;
  margin: auto;
}

.przyciski{
  margin-top: 20px;
  margin-bottom: 10px;
}

button{
    margin: 0 10px;
    padding: 10px;
    flex-grow: 1;
}

.treeselect{
  margin-top: 10px;
  margin-bottom: 10px;
}

span{
  padding: 10px;
}

.checkbox{
  margin: 15px;
}

</style>