<template>
  <div class="creation">





    <div v-if="!this.show" class="create_button" @click="creation_clicked">
          <i class="fa-2x fa-solid fa-circle-plus"></i>
          <p>UTWÓRZ<br>WYDARZENIE</p>
    </div>

    <div v-else class="EventDesc" >

        <h3>Tworzenie wydarzenia</h3>

        
        <input  v-model="title" placeholder="Wprowadź nazwę wydarzenia">

        <div class="flex_container select_time">
          
            <div class="select_time_text"><h4>Wybierz<br>godzinę:</h4></div>
            <div><v-date-picker mode="time" v-model="date"  is24hr is-dark :minute-increment="5"/></div>

        </div>

        <Treeselect
        class="treeselect"
        :searchable=false
        :clearable=false
        placeholder="Wybierz kategorię"
        
        :multiple="true"
        :options="options"
        v-model="value"
        :default-expand-level="0"
        >
        <div slot="value-label" slot-scope="{ node }">{{ node.raw.customLabel }}</div>
        </Treeselect>

        <div class="checkbox">
          <i v-if="this.checked" @click="checkbox_clicked" class="fa-solid fa-circle-check"></i>
          <i v-else @click="checkbox_clicked" class="fa-regular fa-circle"></i>
          <span @click="checkbox_clicked">Wyślij maila informującego</span>
        </div>


      <div class="buttons_container">


            <div v-if="loading_buttons">
                  <loadingItem small />

            </div>

            <div v-else>

                  <div v-if="is_error">
                    <p class="error" v-for="error in errors" :key="error.id">{{error}}</p>
                  </div>

                  <div v-if="!confirm_to_be_clicked" class="flex_container przyciski">

                      <button @click="creation_clicked"> Anuluj </button>
                      <button @click="creation_create_button"> Utwórz </button>

                  </div>

                  <div v-else class="flex_container przyciski">

                      <button @click="cancel_confirm"> Anuluj </button>
                      <button @click="create_confirm"> Potwierdź utworzenie </button>

              </div>


            </div>

      </div>
        

    </div>


  </div>
</template>
<script>
import Treeselect from '@riophae/vue-treeselect'
import loadingItem from "@/components/loadingItem.vue";


export default {
  name: "calendarCreateEvent",
  components: {
    Treeselect,
    loadingItem,
  },
  props:{
      selected_day: String,
  },
  data() {
    return {
      show: false,
      date: {},
      title: "",

      value: [],
      value_private: -1,
      options: this.$store.state.options_create,
      checked: false,

      confirm_to_be_clicked: false,
      loading_buttons: false,
      is_error: false,
      errors: [],
    };
  },
  
  created() {
    this.on_start();
    
  },
  methods: {
    creation_clicked(){
      this.show = !this.show;

      if(this.show == true){
          let date_string = this.selected_day.split('.')
          this.date = new Date(date_string[2]+"-"+date_string[1]+"-"+date_string[0])

          this.value = [];
          this.title = "";

          this.confirm_to_be_clicked= false;
          this.create_button_clicked= false;
          this.is_error = false;
          this.errors = [];
          this.checked = false;

      }
    
    },

    cancel_confirm(){
        this.confirm_to_be_clicked= false;
    },

    create_confirm(){
        // todo update event to backend
    },

    
    checkbox_clicked(){
      this.checked = !this.checked;
    },
    
    on_start(){
      this.show = false;
      this.value = [];
      this.title = "";

      this.options =  this.$store.state.options_create;
      this.value_private = this.options.find(opt => opt.customLabel == "Prywatne").id;
 

    },

    creation_create_button(){
        this.loading_buttons = true;

        this.errors = [];
        this.is_error = false;

        if(this.value.length == 0){
          this.is_error = true;
          this.errors.push("Kategoria nie została wybrana")
        } 
        if(this.title == ""){
          this.is_error = true;
          this.errors.push("Wydarzenie nie ma nazwy")
        } 
        if(this.value.includes(this.value_private) && this.value.length > 1){
          this.is_error = true;
          this.errors.push("Wydarzenie nie może być jednocześnie prywatne oraz publiczne")
        }

        if(this.is_error == false){
          this.confirm_to_be_clicked = true;
        }


        setTimeout(() => {
          this.loading_buttons = false;
        }, 400)
    },

  },
  watch:{
    }
};
</script>
<style lang="scss" scoped>

.EventDesc{
  background-color: var(--bottom_gradient);
  border-radius: 5px;
  border: 1px solid var(--top_gradient);
  margin: 5px;
  padding: 10px 10px 0 10px;

  _margin-bottom: 400px;


}

.create_button{
  margin: 30px;
}
.create_button>p{
  margin: 6px;
}

input{
  width: 90%;
  font-size: 20px;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  text-align: center;
  border-radius: 7px;

  color: var(--secondary_text);
  background-color: var(--top_gradient);
  border: 1px solid var(--top_gradient);

}



.buttons_container{
  _height: 50px;
}

.select_time>div{
  flex-grow: 1;
}

.select_time_text{
  text-align: right;
  margin: auto;
}

.przyciski{
  margin-top: 20px;
  margin-bottom: 10px;
}

button{
    _margin: 0 20px;
    _padding: 10px;
    _flex-grow: 1;
}


.treeselect{
  margin-top: 10px;
}

span{
  padding: 10px;
}

.checkbox{
  margin: 15px;
}

</style>