<template>
    <div class="wholeEvent">
      <div class="EventDesc">

          <div class="bottom_bar">
            <calendarOneEventCommisionBookMark 
              v-for="commision in event.commisions" :key="commision.id"
              :commision="commision"
            />

          </div>


        
        <div class="right">

            <h2>{{event.note}}</h2>
            
            <div class="datetime">

                <div class="date" v-if="selected_day == '' || selected_day == null">
                  {{event.day}} {{this.$func.getFullMonthName(event.month)}}
                </div>
                {{event.time_hour}}:{{event.time_minute}}

            </div>

        </div>

        <div class="left" @click="edit_show">

          <div class="checkbox">
              <i v-if="this.show" class="fa-xl fa-solid fa-xmark"></i>
              <i v-else class="fa-xl fa-solid fa-ellipsis-vertical"></i>
          </div>

        </div>
    
    </div>
    <div v-show="show" class="collapse">
        <calendarEdit 
          :event="event"
          ref="calendarEdit"
          :is_organizer="is_organizer"
        />
    </div>

  </div>
</template>
<script>
import calendarOneEventCommisionBookMark from "@/components/calendarOneEventCommisionBookMark.vue";
import calendarEdit from "@/components/calendarEdit.vue";


export default {
  name: "calendarDayEvents",
  components: {
    calendarOneEventCommisionBookMark,
    calendarEdit,
  },
  props:{
      event: Object,
      selected_day: String,
  },
  data() {
    return {
      show: false,
      is_organizer: true, //todo connect with backend, organizer id
    };
  },
  
  created() {

  },
  methods: {
    edit_show(){
      this.show = !this.show;
      this.$refs.calendarEdit.creation_clicked();

/*
                console.log(event.target.parentNode.parentNode);

                let elementContent;
              
                console.log(event.target.parentNode.parentNode.children[1]);
                elementContent = event.target.parentNode.parentNode.children[1];
              
                console.log(elementContent.style)

              if (elementContent.style.maxHeight != 0){
                  // zwijanie odpowiedzi
                  
                  elementContent.style.maxHeight = 0;
              } else {
                  // wyświetlanie odpowiedzi
                  elementContent.style.maxHeight = elementContent.scrollHeight + "px";
              }
            
        */


    },
    edit_on_start(){
      this.show = false;
      this.$refs.calendarEdit.on_start();
    },
  },
  watch:{
    }
};
</script>
<style lang="scss" scoped>

.EventDesc{
  background-color: var(--bottom_gradient);
  border-radius: 5px;
  border: 1px solid var(--top_gradient);
  margin: 5px;
  padding: 0px 10px 10px 10px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

}
.wholeEvent{
  margin-bottom: 20px;
}
.left{
  width: 10%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.right{
  width: 90%;
}

.date{
  display: inline;
}

.datetime{

}

.bottom_bar{
  width: 100%;
  _height: 20px;
  margin-top: -2px;
  _margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

}

.collapse{
    _max-height: 0px;
    _overflow: hidden;
    _transition: max-height 0.2s ease-out;

}

</style>