<template>
  <div>
  <i class="bar_info fa-xl fa-solid fa-bookmark"
       :style="this.$func.getCommisionColor(this.$store.state.commision_colors, commision)"
  ></i>
  </div>
</template>
<script>


export default {
  name: "calendarOneEventCommisionBar",
  props:{
      commision: Number,
  },
};
</script>
<style lang="scss" scoped>

.bar_info{
    _padding-top: 4px;
    _flex-grow: 1;
    _width: 15px;
    _margin-left: 5px;
    margin-right: 5px;

}


</style>